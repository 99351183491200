
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
@Component({})
export default class Home extends Vue {
  private win = window;
  private base = window.location;
  private left = false;

  private gastronomiaGo(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "DetalleLocales" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "DetalleLocales",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
