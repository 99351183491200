
import { Component, Vue } from "vue-property-decorator";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import Notify from 'quasar/src/plugins/Notify.js';;
import axios from "axios";
@Component({})
export default class OficinaTuristica extends Vue {
  private win = window;
  private base = window.location;
  private left = false;
  private nombreWeb: any = "";
  private dataSantuario: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoAtractivo();
    }
  }

  private getInfoAtractivo() {
    axios
      .get("atractivo/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.dataSantuario = res.data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private video() {
    if (this.dataSantuario.youtube && this.dataSantuario.youtube != "") {
      var url = this.dataSantuario.youtube;
      url = url.replace("watch?v=", "embed/");
      return url;
    }
  }

  private irGoogleMaps() {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" +
        this.dataSantuario.direccion
    );
  }

  private llamar() {
    const numero = this.dataSantuario.telefono;
    window.open("tel:" + numero);
  }

  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private sendMail() {
    const correo = this.dataSantuario.email;
    window.open("mailto:" + correo);
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
