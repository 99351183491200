import Vue from "vue";

import "./styles/quasar.scss";
import lang from "quasar/lang/es.js";
import "@quasar/extras/fontawesome-v5";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import Quasar from 'quasar/src/vue-plugin.js';import QCarousel from 'quasar/src/components/carousel/QCarousel.js';import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QImg from 'quasar/src/components/img/QImg.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';import Meta from 'quasar/src/plugins/Meta.js';import QPagination from 'quasar/src/components/pagination/QPagination.js';import QMenu from 'quasar/src/components/menu/QMenu.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import QSeparator from 'quasar/src/components/separator/QSeparator.js';import QVideo from 'quasar/src/components/video/QVideo.js';;

Vue.use(Quasar, {
  config: {},
  components: {
    QCarousel,
    QCarouselControl,
    QCarouselSlide,
    QScrollArea,
    QBtn,
    QImg,
    Notify,
    QPagination,
    QMenu,
    QSeparator,
    QVideo
  },
  plugins: {
    Notify,
    Loading,
    Meta
  },
  directives: {
    ClosePopup
  },
  lang: lang
});
