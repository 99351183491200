import { render, staticRenderFns } from "./FooterComponent.vue?vue&type=template&id=359bfb2e&scoped=true"
import script from "./FooterComponent.vue?vue&type=script&lang=ts"
export * from "./FooterComponent.vue?vue&type=script&lang=ts"
import style0 from "./FooterComponent.vue?vue&type=style&index=0&id=359bfb2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359bfb2e",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QImg,QSeparator});
