import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import axios from "axios";

import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   config: { id: "G-7B1XMCTZ0F" }
// }, router);
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_ANALYTICS_ID },
    pageTrackerTemplate(to: any) {
      let title = to.name;
      if (title == 'OficinaTuristica') {
        title = 'Oficina Turistica - Maipú Turismo';
      }
      if (title == 'Comuniquemonos') {
        title = 'Comuniquémonos - Maipú Turismo';
      }
      if (title == 'Atractivos') {
        title = 'Atractivos Turísticos - Maipú Turismo';
      }
      if (title == 'DetalleAtractivo') {
        title = 'Atractivos Turísticos - Maipú Turismo';
      }
      if (title == 'Gastronomia') {
        title = 'Gastronomía - Maipú Turismo';
      }
      if (title == 'DetalleLocales') {
        title = 'Locales Gastronómicos - Maipú Turismo';
      }
      if (title == 'Tours') {
        title = 'Tours - Maipú Turismo';
      }
      if (title == 'Inscribete') {
        title = 'Inscribete - Maipú Turismo';
      }
      if (title == 'DetalleTours') {
        title = 'Tours - Maipú Turismo';
      }
      if (title == 'Historico') {
        title = 'Historico - Maipú Turismo';
      }
      if (title == 'Historia') {
        title = 'Historia de Maipú - Maipú Turismo';
      }
      if (title == 'Batalla') {
        title = 'Batalla de Maipú - Maipú Turismo';
      }
      if (title == 'Smapa') {
        title = 'Histórico de Smapa - Maipú Turismo';
      }
      if (title == 'Personajes') {
        title = 'Personajes Destacados - Maipú Turismo';
      }
      if (title == 'Productos') {
        title = 'Productos Locales - Maipú Turismo';
      }
      if (title == 'DetallesProductos') {
        title = 'Productos Locales - Maipú Turismo';
      }
      if (title == 'Licores') {
        title = 'Licores - Maipú Turismo';
      }
      if (title == 'Textil') {
        title = 'Textil y Vestuarios - Maipú Turismo';
      }
      if (title == 'Cosmetica') {
        title = 'Cosmetica - Maipú Turismo';
      }
      if (title == 'Joyas') {
        title = 'Joyas - Maipú Turismo';
      }
      if (title == 'Otros') {
        title = 'Otros - Maipú Turismo';
      }
      if (title == "Home") {
        title = "Home - Maipú Turismo";
      }
      if (to.params.nombre) {
        if (title == "Maipú Turismo") {
          title = to.params.nombre.replaceAll("-", " ") + " - " + title;
        } else {
          title = title + " - " + to.params.nombre.replaceAll("-", " ");
        }
      }
      return {
        page_title: title,
        page_path: to.path
      };
    }
  },
  router
);

Vue.config.productionTip = false;
axios.defaults.baseURL =
  process.env.VUE_APP_API_PATH || "http://localhost:8000/api";
axios.defaults.headers.common["Authorization"] =
  process.env.AUTHORIZATION || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9";

//Truncate text
const filter = function (text: any, length: any, clamp: any) {
  clamp = clamp || "...";
  const node = document.createElement("div");
  node.innerHTML = text;
  const content = node.textContent;
  if (content) {
    return content.length > length ? content.slice(0, length) + clamp : content;
  } else {
    return text;
  }
};
Vue.filter("truncate", filter);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
