import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentPage: "",
    idCategoriaNoticia: localStorage.getItem("idCategoriaNoticia") || "",
    idNoticia: localStorage.getItem("idNoticia") || ""
  },
  mutations: {
    SET_CURRENT_PAGE(state, data) {
      state.currentPage = data;
    },
    SET_CATEGORIA_BLOG(state, data) {
      state.idCategoriaNoticia = data;
      localStorage.setItem("idCategoriaNoticia", data);
    },
    UNSET_CATEGORIA_BLOG(state) {
      state.idCategoriaNoticia = "";
      localStorage.removeItem("idCategoriaNoticia");
    },
    SET_BLOG(state, data) {
      state.idNoticia = data;
      localStorage.setItem("idNoticia", data);
    },
    UNSET_BLOG(state) {
      state.idNoticia = "";
      localStorage.removeItem("idNoticia");
    }
  },
  actions: {},
  modules: {}
});
