
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import Notify from 'quasar/src/plugins/Notify.js';;
import axios from "axios";

@Component({})
export default class TipicaChilena extends Vue {
  private win = window;
  private base = window.location;
  private left = false;
  private productosId: any;
  private dataProducto: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.id_productos &&
      this.$router.currentRoute.params.id_productos != ""
    ) {
      this.productosId = this.$router.currentRoute.params.id_productos;
      this.getInfoProducto();
    }
  }

  private getInfoProducto() {
    axios
      .get("productos/detalle?id=" + this.productosId)
      .then((res: any) => {
        this.dataProducto = res.data;
        for (let i = 0; i < this.dataProducto.length; i++) {
          if (this.dataProducto[i].imagen != "") {
            this.dataProducto[i].imagen =
            process.env.VUE_APP_MEDIA_URL + "/" +
              this.dataProducto[i].imagen;
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
