var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-page',{staticStyle:{"max-width":"1440px","margin":"auto","min-height":"0px !important"}},[_c('div',{staticClass:"row col-12"},[_c('q-header',{staticClass:"header_blue",attrs:{"elevated":""}},[_c('q-toolbar',{staticClass:"header_blue justify-center"},[_c('div',{staticClass:"row col-12 q-pa-xs"},[_c('div',{staticClass:"col-xs-5 col-md-6 q-mt-md"},[_c('p',[_vm._v("contacto@maiputurismo.cl")])]),_c('div',{staticClass:"row justify-end col-6 q-mt-sm"},[_c('div',{staticClass:"col-2 row justify-end"},[_c('img',{staticClass:"cursor-pointer q-pa-xs",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("@/assets/logos/logo_facebook.png")},on:{"click":function($event){return _vm.win.open(
                    'https://es-la.facebook.com/municipalidadmaipuchile/',
                    '_blank'
                  )}}})]),_c('div',{staticClass:"col-2 row justify-center"},[_c('img',{staticClass:"cursor-pointer q-pa-xs",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("@/assets/logos/logo_twitter.png")},on:{"click":function($event){return _vm.win.open('https://twitter.com/MaipuRenace', '_blank')}}})]),_c('div',{staticClass:"col-2 row justify-start"},[_c('img',{staticClass:"cursor-pointer q-pa-xs",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":require("@/assets/logos/logo_instagram.png")},on:{"click":function($event){return _vm.win.open(
                    'https://www.instagram.com/maiputurismo/',
                    '_blank'
                  )}}})])])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }