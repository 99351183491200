
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import Notify from 'quasar/src/plugins/Notify.js';;
import axios from "axios";

interface DataContacto {
  rut: string;
  nombre: string;
  email: string;
  telefono: string;
  direccion: string;
  mensaje: string;
  asunto: string;
  fecha_nacimiento: string | any;
}

interface Tipos {
  value: number | any;
  label: string;
}
@Component({})
export default class Comuniquemonos extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private left = false;
  private formattedFecha = "";
  private rutContacto = "";

  private asuntos = [
    { value: 1, label: "Viajes Soñados" },
    { value: 2, label: "Viajando Juntos" },
    { value: 3, label: "Maipú Turístico" },
    { value: 4, label: "Felicitaciones" },
    { value: 5, label: "Sugerencias" },
    { value: 6, label: "Reclamos" }
  ];

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  private infoContacto: DataContacto = {
    rut: "",
    nombre: "",
    email: "",
    telefono: "",
    direccion: "",
    mensaje: "",
    fecha_nacimiento: null as Date | null,
    asunto: ""
  };

  private selectAsunto: Tipos = {
    value: 0,
    label: "Asunto*"
  };

  @Watch("selectAsunto")
  private asunto(newValue: any) {
    this.infoContacto.asunto = newValue.label;
  }

  @Watch("rutContacto")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.infoContacto.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutContacto = `${rut}-${dv}`;
    } else {
      this.rutContacto = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.infoContacto.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.infoContacto.fecha_nacimiento = new Date(birthDate);
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private enviarCorreo() {
    if (
      this.infoContacto.rut == "" ||
      this.validateRut(this.infoContacto.rut) == false
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (
      this.infoContacto.fecha_nacimiento == null ||
      !this.checkFecha(this.infoContacto.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha de nacimiento.",
        icon: "report_problem",
        position: "top",
        timeout: 5000
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.infoContacto.fecha_nacimiento.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
            icon: "report_problem",
            position: "top",
            timeout: 5000
          });
          return;
        }
      }
    }
    if (this.infoContacto.nombre == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.infoContacto.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.telefono == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.infoContacto.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.direccion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.asunto == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese el asunto por el cual desea hacer su contacto.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.infoContacto.mensaje == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un mensaje para conocer más de su contacto.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 200 // ms
    });
    axios
      .post("contacto", {
        rut: this.infoContacto.rut,
        nombre: this.infoContacto.nombre,
        email: this.infoContacto.email,
        telefono: this.infoContacto.telefono,
        direccion: this.infoContacto.direccion,
        mensaje: this.infoContacto.mensaje,
        asunto: this.infoContacto.asunto,
        fecha_nacimiento: this.infoContacto.fecha_nacimiento
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente su mensaje.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.$router.push({ name: "Home" }).catch(err => {
          console.log(err);
        });
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        const mensaje = err.response.data.message;
        if (mensaje == "NOT_ALLOW") {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un problema, intentelo más tarde.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_RUT") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_RUT") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_FECHA_NACIMIENTO") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar una fecha de nacimiento.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_NOMBRE") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un nombre válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_EMAIL") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un correo válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_TELEFONO") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un teléfono válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_DIRECCION") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar una dirección válida.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_ASUNTO") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un asunto válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else if (mensaje == "INVALID_PARAM_MENSAJE") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un mensaje válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un problema, intentelo más tarde.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }

  private checkFecha(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
