
import { Component, Watch, Vue } from "vue-property-decorator";
@Component
export default class HeaderComponent extends Vue {
  private win = window;
  private base = window.location;
  private left = false;
  private barraBlanca = false;

  @Watch("$store.state.currentPage")
  private routerWatch(newValue: string) {
    if (newValue == "Home") {
      this.barraBlanca = false;
    } else {
      this.barraBlanca = true;
    }
  }
  private mounted() {
    if (this.$store.state.currentPage == "Home") {
      this.barraBlanca = false;
    } else {
      this.barraBlanca = true;
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
