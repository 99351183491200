<template>
  <q-layout view="hhh lpr fff">
    <HeaderComponent />
    <q-page-container>
      <router-view />
    </q-page-container>
    <FooterComponent />
  </q-layout>
</template>

<script>
import FooterComponent from "@/components/Common/FooterComponent.vue"; // @ is an alias to /src
import HeaderComponent from "@/components/Common/HeaderComponent.vue";

export default {
  name: "LayoutDefault",

  components: {
    FooterComponent,
    HeaderComponent
  },

  data() {
    return {
      leftDrawerOpen: false,
      win: window,
      base: window.location
    };
  }
};
</script>

<style lang="scss">
.footer-image {
  z-index: 3000 !important;
}
.footer-image-container {
  background-color: rgb(255, 255, 255) !important;
  margin-bottom: -5px;
}
.footer {
  background-color: #8fba06 !important;
}
.logo-adelante-image {
  width: 150px !important;
}
.district {
  font-family: "Open Sans";
  font-size: 16px;
}
.logo-marcador {
  width: 50px !important;
}
.address-text {
  font-family: "Open Sans";
}
.logo-footer-right {
  width: 60px !important;
  height: auto;
  text-align: center;
}
.separator {
  width: 75% !important;
  margin-left: 13% !important;
}
body {
  font-family: "Open Sans" !important;
}
open-sans {
  font-family: "Open Sans";
}
@media (max-width: 599px) {
  .footer-text {
    font-size: 11px;
  }
}
</style>
