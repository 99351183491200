
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import Notify from 'quasar/src/plugins/Notify.js';;
import axios from "axios";

@Component({})
export default class TipicaChilena extends Vue {
  private win = window;
  private base = window.location;
  private left = false;
  private bgCard: any = "";
  private colores = ["#4abae7", "#e91e63", "#fbe923", "#006bd1"];
  private contador = 0;

  private instagram: any = "";

  private nombreWeb: any = "";
  private dataGastronomia: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoGastronomia();
    }
  }

  private getInfoGastronomia() {
    axios
      .get("gastronomia/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.dataGastronomia = res.data;
        for (let i = 0; i < this.dataGastronomia.length; i++) {
          if (this.dataGastronomia[i].imagen != "") {
            this.dataGastronomia[i].imagen =
              " url_media" +
              this.dataGastronomia[i].imagen;
          }
        }

        // for (let i = 0; i < this.dataGastronomia.length; i++) {
        //   console.log(1);
        //   if (this.dataGastronomia[i].instagram != "") {
        //     let nombreInsta = this.dataGastronomia[i].instagram;
        //     this.instagram = "@" + nombreInsta.slice(26, 38);
        //   }
        // }
      })
      .catch((err: any) => {
        if (err.response.data.message == "GASTRONOMIA_NOT_FOUND") {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message: "NO SE HAN ENCONTRADO LOCALES",
            icon: "report_problem",
            position: "top",
            timeout: 5000
          });

          this.$router.push({ name: "Home" }).catch(err => {});
        }

        if (err.response.data.message == "NOT_ALLOW") {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message: "NO SE HAN ENCONTRADO LOCALES",
            icon: "report_problem",
            position: "top",
            timeout: 5000
          });

          this.$router.push({ name: "Home" }).catch(err => {});
        }
      });
  }

  private irGoogleMaps(data: any) {
    window.open("https://www.google.com/maps/search/?api=1&query=" + data);
  }

  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }

  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  // private bgDinamico() {

  //   for(let i = 0; i < this.dataGastronomia.length; i++){

  //       this.bgCard = 'background-color: ' + this.colores[Math.floor(Math.random() * this.colores.length)];
  //       console.log(this.bgCard);

  //   }

  // }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
