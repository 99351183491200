
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
@Component({})
export default class OficinaTuristica extends Vue {
  private win = window;
  private base = window.location;
  private left = false;

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
