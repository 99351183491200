import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store";
import Home from "../views/Home.vue";
import OficinaTuristica from "../views/OficinaTuristica/OficinaTuristica.vue";
import Atractivos from "../views/Atractivos/Atractivos.vue";
import DetalleAtractivo from "../views/Atractivos/DetalleAtractivo.vue";
import Comuniquemonos from "../views/OficinaTuristica/Comuniquemonos.vue";
import Gastronomia from "../views/Gastronomia/Gastronomia.vue";
import DetalleLocales from "../views/Gastronomia/DetalleLocales.vue";
import Tours from "../views/Tours/Tours.vue";
import Inscribete from "../views/Tours/Inscribete.vue";
import DetalleTours from "../views/Tours/DetalleTours.vue";
import Historico from "../views/Historico/MaipuHistorico.vue";
import Batalla from "../views/Historico/BatallaMaipu.vue";
import Historia from "../views/Historico/HistoriaMaipu.vue";
import Smapa from "../views/Historico/HistoriaSmapa.vue";
import Personajes from "../views/Historico/PersonajesDestacados.vue";
import Productos from "../views/Productos/ProductosLocales.vue";
import DetallesProductos from "../views/Productos/DetallesProductos.vue";
import Licores from "../views/Productos/Licores.vue";
import Textil from "../views/Productos/Textil.vue";
import Cosmetica from "../views/Productos/Cosmetica.vue";
import Joyas from "../views/Productos/Joyas.vue";
import Otros from "../views/Productos/Otros.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/oficina-turistica",
    name: "OficinaTuristica",
    component: OficinaTuristica
  },

  {
    path: "/oficina-turistica/comuniquemonos",
    name: "Comuniquemonos",
    component: Comuniquemonos
  },
  {
    path: "/atractivos",
    name: "Atractivos",
    component: Atractivos
  },
  {
    path: "/atractivos/:nombre",
    name: "DetalleAtractivo",
    component: DetalleAtractivo
  },

  {
    path: "/gastronomia",
    name: "Gastronomia",
    component: Gastronomia
  },

  {
    path: "/gastronomia/:nombre",
    name: "DetalleLocales",
    component: DetalleLocales
  },

  {
    path: "/tours",
    name: "Tours",
    component: Tours
  },

  {
    path: "/tours/inscribete",
    name: "Inscribete",
    component: Inscribete
  },

  {
    path: "/tours/:nombre",
    name: "DetalleTours",
    component: DetalleTours
  },

  {
    path: "/maipu-historico",
    name: "Historico",
    component: Historico
  },
  {
    path: "/maipu-historico/historia-maipu",
    name: "Historia",
    component: Historia
  },
  {
    path: "/maipu-historico/batalla-de-maipu",
    name: "Batalla",
    component: Batalla
  },
  {
    path: "/maipu-historico/historio-de-smapa",
    name: "Smapa",
    component: Smapa
  },
  {
    path: "/maipu-historico/personajes-destacados",
    name: "Personajes",
    component: Personajes
  },
  {
    path: "/productos-locales",
    name: "Productos",
    component: Productos
  },
  {
    path: "/productos-locales/:nombre",
    name: "DetallesProductos",
    component: DetallesProductos
  },
  {
    path: "/productos-locales/licores",
    name: "Licores",
    component: Licores
  },
  {
    path: "/productos-locales/textil-y-vestuario",
    name: "Textil",
    component: Textil
  },
  {
    path: "/productos-locales/cosmetica",
    name: "Cosmetica",
    component: Cosmetica
  },
  {
    path: "/productos-locales/joyas-y-accesorios",
    name: "Joyas",
    component: Joyas
  },
  {
    path: "/productos-locales/otros",
    name: "Otros",
    component: Otros
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  Store.commit("SET_CURRENT_PAGE", to.name);
  next();
});
export default router;
