
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
@Component({})
export default class OficinaTuristica extends Vue {
  private win = window;
  private base = window.location;
  private left = false;

  private nombreWeb: any = "";
  private dataTours: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoTours();
    }
  }

  private getInfoTours() {
    axios
      .get("tour/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.dataTours = res.data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private video() {
    if (this.dataTours.youtube && this.dataTours.youtube != "") {
      var url = this.dataTours.youtube;
      url = url.replace("watch?v=", "embed/");
      return url;
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
