
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
@Component({})
export default class PersonajesDestacados extends Vue {
  private win = window;
  private base = window.location;
  private left = false;
  private dataPersonajes: any = "";

  private mounted() {
    this.getInfoPersonajes();
  }

  private getInfoPersonajes() {
    axios
      .get("personajes/list")
      .then((res: any) => {
        this.dataPersonajes = res.data;
        for (let i = 0; i < this.dataPersonajes.length; i++) {
          if (this.dataPersonajes[i].imagen != "") {
            this.dataPersonajes[i].imagen =
              " url_media" +
              this.dataPersonajes[i].imagen;
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private video(idVideo: any) {
    for (let i = 0; i < this.dataPersonajes.length; i++) {
      if (this.dataPersonajes[i].id == idVideo) {
        var url = this.dataPersonajes[i].youtube;
        url = url.replace("watch?v=", "embed/");
        return url;
      }
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
